<template>
    <mdb-card>
        <mdb-card-body>
            <mdb-card-title>
                <i class="fa fa-key"></i>&nbsp;&nbsp;修改登入密碼
            </mdb-card-title>
            <mdb-input label="目前密碼"
                       type="password"
                       v-model="old_pw"></mdb-input>

            <mdb-input label="新密碼"
                       type="password"
                       v-model="new_pw"></mdb-input>

            <mdb-input label="新密碼確認"
                       type="password"
                       v-model="new_pw_confirm"></mdb-input>
            <div class="text-sm-right text-md-left">
                <mdb-btn color="primary"
                         icon="save"
                         @click="change_pw">
                    送&nbsp;&nbsp;出
                </mdb-btn>
            </div>
        </mdb-card-body>
    </mdb-card>
</template>
<script>
import { mdbCard, mdbCardBody, mdbCardTitle, mdbInput, mdbBtn } from "mdbvue";
export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbCardTitle,
    mdbInput,
    mdbBtn,
  },
  data() {
    return {
      old_pw: "",
      new_pw: "",
      new_pw_confirm: "",
    };
  },
  mounted() {
    console.clear();
  },
  updated() {},
  methods: {
    change_pw() {
      let vue = this;
      vue.$store
        .dispatch("post_form", {
          payload: {
            url: "account/password_process",
            data: {
              id: vue.$store.state.user.id,
              old_password: vue.old_pw,
              password: vue.new_pw,
              confirm: vue.new_pw_confirm,
            },
          },
        })
        .then((res) => {
          if (res.data.status == "login_fail") {
            vue.$swal.fire({
              icon: "warning",
              title: res.data.msg,
              toast: true,
              timer: 1500,
              showConfirmButton: false,
            });
          } else if (res.data.status == "success") {
            vue.$swal.fire({
              icon: "success",
              title: res.data.msg,
              timer: 1500,
              showConfirmButton: false,
              didClose() {
                vue.$router.push({
                  path: "/",
                });
              },
            });
          }
        });
    },
  },
};
</script>